<template>
    <form class="">
        <div class="form-group mb-3">
            <label class="">Status</label>
            <select class="custom-select" v-validate="{required: true}" v-model="data.status"
            name="status" placeholder="--select billing period--" :class="{ 'is-invalid': errors.has('status')}">
                <option disabled value="">--select billing period--</option>
                <option value="active">Active</option>
                <option value="past_due">Past due</option>
                <option value="canceled">Canceled</option>
            </select>
            <span class="text-danger small"  v-show="errors.has('status')">{{ errors.first('status') }}</span>
        </div>
        <div class="form-group mb-3">
            <label for="current_period_days">
                    Current period days
                <span class="text-danger">*</span>
            </label>
            <input type="number" v-validate="{required: true}"
            v-model="data.current_period_days" id="current_period_days" name="current_period_days"
            class="form-control" :class="{ 'is-invalid': errors.has('current_period_days') }" placeholder="Current period days" />
            <span class="text-danger invalid-feedback"  v-show="errors.has('current_period_days')">{{ errors.first('current_period_days') }}</span>
        </div>
        <div class="form-group mb-3">
            <label class="d-block">Current period start</label>
            <date-picker v-model="data.current_period_start" placehoder="Choose Date"
            :editable="false" :first-day-of-week="1"
            type="datetime" format="YYYY-MM-DD hh:mm" lang="en"></date-picker>
        </div>
        <div class="form-group mb-3">
            <label class="d-block">Current period end</label>
            <date-picker v-model="data.current_period_end" placehoder="Choose Date"
            :editable="false" :first-day-of-week="1"
            type="datetime" format="YYYY-MM-DD hh:mm" lang="en"></date-picker>
        </div>
        <div class="d-flex mt-3 justify-content-end">
          <b-button variant="primary" @click.prevent="update()" type="button">Update</b-button>
        </div>
    </form>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
    components:{
        DatePicker,
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
    },
    data(){
        return{
            data: {
                status: "",
                current_period_days: null,
                current_period_start: null,
                current_period_end: null
            },
        }
    },
    methods:{
        update(){
           this.$validator.validateAll().then(result => {
                if (!result) {this.alertError("Form not valid"); return }
                const formData = new FormData()
                this.$store.dispatch("changeLoaderValue", true)
                formData.append('data', JSON.stringify(this.data))
                this.$http.post(`/subscriptions/${this.editItem.id}/update`,formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$emit("update", response.data.data)
                        this.resetForm()
                    }
                })
            
            })
        },
        resetForm(){
            this.$validator.reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        this.data = this.$options.filters
                    .patchUpdatables(this.data, this.editItem)
        this.data.current_period_start = new Date(this.data.current_period_start)
        this.data.current_period_end = new Date(this.data.current_period_end)
    }
}
</script>

<style>

</style>